
  
  .sectionPortfolio {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 150px;
    position: relative;
    padding-top: 10vh;
}  

.svg-container {
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-position: center;
}

.responsive-svg {
  width: 100%;
  height: auto;
  margin-top: -130px;
}