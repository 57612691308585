.section2outline {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 100px;
    position: relative;
}

.subheadingText{
    font-family: 'DIN-Regular';
    font-size: 22px;
}

.textContent{
 padding-bottom: 30px;
 justify-content: center;
}

.innerCOntainer{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
}

.background-svg2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;  /* Ensure the SVG is below the text */
}

.section2Header{
    text-wrap: nowrap;
    text-align: center;
}

.section2Para{
    text-wrap: nowrap;
    text-align: center;
    font-size: 14px;
}

/* animation */
@keyframes moveCloudToLeft {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-50px);
    }
}

@keyframes moveCloudToRight {

    0%,
    100% {
        transform: translateX(-100px);
    }

    50% {
        transform: translateX(-50px);
    }
}

.section2outline #cloud_2_1 {
    animation: moveCloudToLeft 5s linear infinite;
}

.section2outline #could_2_2 {
    animation: moveCloudToLeft 5s linear infinite;
}

.section2outline #cloud_2_4 {
    animation: moveCloudToRight 5s linear infinite;
}

@keyframes moveWindToRight {
    0% {
        transform: translateX(0);
        opacity: 0;
    }

    10% {
        transform: translateX(-10px);
        opacity: 0.1;
    }

    20% {
        transform: translateX(-20px);
        opacity: 0.2;
    }

    50% {
        transform: translateX(-50px);
        opacity: 1;
    }

    80% {
        transform: translateX(-80px);
        opacity: 0.2;
    }

    90% {
        transform: translateX(-90px);
        opacity: 0.1;
    }

    100% {
        transform: translateX(-100px);
        opacity: 0;
    }
}

@keyframes moveWindToLeft {
    0% {
        transform: translateX(0);
        opacity: 0;
    }

    10% {
        transform: translateX(10px);
        opacity: 0.1;
    }

    20% {
        transform: translateX(20px);
        opacity: 0.2;
    }

    50% {
        transform: translateX(50px);
        opacity: 1;
    }

    80% {
        transform: translateX(80px);
        opacity: 0.2;
    }

    90% {
        transform: translateX(90px);
        opacity: 0.1;
    }

    100% {
        transform: translateX(100px);
        opacity: 0;
    }
}

.section2outline #wind_2_4 {
    animation: moveWindToRight 5s linear infinite;
}

.section2outline #wind_2_3 {
    animation: moveWindToLeft 5s linear infinite;
}
.section2outline #wind_2_1 {
    animation: moveWindToLeft 5s linear infinite;
}

.section2outline #wind_2_2 {
    animation: moveWindToLeft 5s linear infinite;
}

@keyframes zoomAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .section2outline #sun {
    animation: zoomAnimation 2s ease-in-out infinite;
    transform-origin: center center;
    transform-box: border-box;
    transform-box: fill-box;
  }
  

  @keyframes moveAnimation {
    0% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(-15px);
    }
    80% {
      transform: translate(-15px, 5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .section2outline #video {
    animation: moveAnimation 4s ease-in-out infinite;
  }
  