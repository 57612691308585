.section3outline {
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-position: center;
}

/* Zoom Animation */
@keyframes zoomAnimation {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(0.90);
    }

    20% {
        transform: scale(0.80);
    }

    30% {
        transform: scale(0.70);
    }

    40% {
        transform: scale(0.60);
    }

    50% {
        transform: scale(0.50);
    }

    60% {
        transform: scale(0.60);
    }

    70% {
        transform: scale(0.70);
    }

    80% {
        transform: scale(0.80);
    }

    90% {
        transform: scale(0.90);
    }

    100% {
        transform: scale(1);
    }
}

#s3_sun {
    animation: zoomAnimation 5s ease-in-out infinite;
    transform-origin: center center;
    transform-box: border-box;
    transform-box: fill-box;
}

/* Appear/Disappear */
@keyframes appearDisappearAnimation {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes appearDisappearAnimationReverse {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

#s3_cloud1,
#s3_wind2 {
    animation: appearDisappearAnimation 5s ease-in-out infinite;
    transform-origin: center center;
    transform-box: border-box;
    transform-box: fill-box;
}

#s3_wind4 {
    animation: appearDisappearAnimationReverse 5s ease-in-out infinite;
    transform-origin: center center;
    transform-box: border-box;
    transform-box: fill-box;
}

@keyframes moveCloudToLeft {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-50px);
    }
}

@keyframes moveCloudToRight {

    0%,
    100% {
        transform: translateX(-100px);
    }

    50% {
        transform: translateX(-50px);
    }
}

#s3_cloud2,
#s3_cloud4 {
    animation: moveCloudToRight 5s linear infinite;
}

#s3_cloud3 {
    animation: moveCloudToLeft 5s linear infinite;
}

@keyframes moveWind1ToRight {

    0%,
    100% {
        transform: translateX(-35px);
    }

    50% {
        transform: translateX(35px);
    }
}

#s3_wind1 {
    animation: moveWind1ToRight 5s linear infinite;
}

@keyframes moveWind3ToRight {

    0%,
    100% {
        transform: translateX(-35px);
    }

    50% {
        transform: translateX(100px);
    }
}

#s3_wind3 {
    animation: moveWind3ToRight 6s linear infinite;
}

@keyframes swingGlass {

    0%,
    100% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(25deg);
    }

    75% {
        transform: rotate(-25deg);
    }
}

#s3_glass2 {
    transform-origin: center center;
    animation: swingGlass 8s linear infinite;
    transform-box: border-box;
    transform-box: fill-box;
}

@keyframes xSwingBag {

    0%,
    100% {
        transform: translateX(-20px);
    }

    50% {
        transform: translateX(20px);
    }
}

#s3_lapBag {
    animation: xSwingBag 3s linear infinite;
}

@keyframes swingHeadphone {

    0%,
    100% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(13deg);
    }

    75% {
        transform: rotate(-6deg);
    }
}

#s3_headphone {
    transform-origin: left bottom;
    animation: swingHeadphone 4s linear infinite;
    transform-box: border-box;
    transform-box: fill-box;
}

@keyframes rightLeftXmovementLine {

    0%,
    100% {
        transform: translateX(-70px);
    }

    50% {
        transform: translateX(-300px);
    }
}

#s3_hLine1 {
    animation: rightLeftXmovementLine 5s linear infinite;
}

@keyframes leftRightXmovementLine {

    0%,
    100% {
        transform: translateX(-50px);
    }

    50% {
        transform: translateX(50px);
    }
}


#s3_hLine2 {
    animation: leftRightXmovementLine 5s linear infinite;
}

@keyframes bottomTopYmovementLine {

    0%,
    100% {
        transform: translateY(-520px);
    }

    50% {
        transform: translateY(80px);
    }
}


#s3_vLine1 {
    animation: bottomTopYmovementLine 5s linear infinite;
}

@keyframes topBottomYmovementLine {

    0%,
    100% {
        transform: translateY(520px);
    }

    50% {
        transform: translateY(80px);
    }
}


#s3_vLine2 {
    animation: topBottomYmovementLine 5s linear infinite ;
}