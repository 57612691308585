body{
    margin: 0px;
    background-image: url('./Assets/background\ pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
}

@font-face {
    font-family: 'DIN-Medium';
    src: url('./Fonts/DIN-MEDI.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'DIN';
    src: url('./Fonts/DIN-BOLD.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'DIN-Regular';
    src: url('./Fonts/DIN-REGU.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'HelveticaNeueLT-ExtBlackCond';
    src: url('./Fonts/helvetica\ lt\ 107\ extra\ black\ condensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'HelveticaNeue LT 107 XBlkCn';
    src: url('./Fonts/helvetica\ lt\ 107\ extra\ black\ condensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica';
    src: url('./Fonts/helvetica-bold_0.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica-Bold';
    src: url('./Fonts/helvetica-bold_0.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  .pointer{
    cursor: pointer;
  }

  .defaultCursor{
    cursor: default;
  }