

.mainOutline{
    background-image: url('./Assets/backgroundtheme.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    align-items: flex-start;
    background-position-y: -31px;
}

.portfolioOutline{
    background-image: url('./Assets/portfolioBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    align-items: flex-start;
    background-position-y: -31px;
}

.subpageOutline{
    background-image: url('./Assets/subPagebackground.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    align-items: flex-start;
    background-position-y: -31px;
}