.section4outline {
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-position: center;
}

@keyframes ySwingRocket {

    0%,
    100% {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(20px);
    }
}

#s4rocket {
    animation: ySwingRocket 1.3s linear infinite;
}

@keyframes ySwingUpwardArrow {

    0%,
    100% {
        transform: translateY(-8px);
    }

    50% {
        transform: translateY(30px);
    }
}

#s4UpwardArrow {
    animation: ySwingUpwardArrow 1s linear infinite;
}

@keyframes mapPointZoomAnimation {
    0% {
      transform: scale(0.5);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(0.5);
    }
  }

#s4MapPoint {
    animation: mapPointZoomAnimation 4s ease-in-out infinite;
    transform-origin: center center;
    transform-box: border-box;
    transform-box: fill-box;
  }

  @keyframes showHideAnimation {
    0%, 49% {
      visibility: visible;
    }
    50%, 100% {
      visibility: hidden;
    }
  }
  
  #s4ChatIcon {
    animation: showHideAnimation 1.8s steps(1) infinite;
  }

  @keyframes joltAnimation {
    0%, 49%, 100% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(0px);
    }
  }
  
  #s4MailAtSymbol {
    animation: joltAnimation 2s steps(1) infinite;
  }

  @keyframes appearMoveDisappear {
    0% {
      opacity: 0;
      transform: translateX(-150px);
    }
    30% {
      opacity: 1;
      transform: translateX(-150px);
    }
    50% {
      opacity: 1;
      transform: translateX(50px);
    }
    70% {
      opacity: 1;
      transform: translateX(-150px);
    }
    100% {
      opacity: 0;
      transform: translateX(-150px);
    }
  }
  
  #s4blueCloud {
    animation: appearMoveDisappear 13s linear infinite;
  }

  @keyframes moveWind1ToRight {

    0%,
    100% {
        transform: translateX(180px);
    }

    50% {
        transform: translateX(0px);
    }
}

#s4_wind1 {
    animation: moveWind1ToRight 6s linear infinite ;
}

@keyframes moveWind2ToRight {

    0%,
    100% {
        transform: translateX(-100px);
    }

    50% {
        transform: translateX(150px);
    }
}

#s4_wind2 {
    animation: moveWind2ToRight 6s linear infinite;
}

#s4_wind3 {
    animation: moveWind2ToRight 6s linear infinite ;
}
  
@keyframes s4RightLeftXmovementLine {

    0%,
    100% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(570px);
    }
}

#s4_hLine1 {
    animation: s4RightLeftXmovementLine 5s linear infinite;
}

@keyframes s4LeftRightXmovementLine {

    0%,
    100% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(-570px);
    }
}


#s4_hLine2 {
    animation: s4LeftRightXmovementLine 5s linear infinite;
}

@keyframes s4BottomTopYmovementLine {

    0%,
    100% {
        transform: translateY(-150px);
    }

    50% {
        transform: translateY(400px);
    }
}


#s4_vLine1 {
    animation: s4BottomTopYmovementLine 5s linear infinite;
}

@keyframes s4TopBottomYmovementLine {

    0%,
    100% {
        transform: translateY(400px);
    }

    50% {
        transform: translateY(-150px);
    }
}


#s4_vLine2 {
    animation: s4TopBottomYmovementLine 5s linear infinite ;
}