.headerOuter{    
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    justify-content: center;
    z-index: 10;
}

.headerMain{
    min-height: 48px;
    width: 65% !important;    
}

.headerMainLeft{
    align-items: center;
    display: flex;
}

.headerMainRight{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.headerMainRight button{
    margin-left: 11%;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-wrap: nowrap;
}
