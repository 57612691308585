
.client-svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh; /* Full viewport height */
}

.client-responsive-svg {
  width: 100%;
  height: auto;
}