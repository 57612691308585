.testimonial-svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90vh;
  /* Full viewport height */
}

.testimonial-responsive-svg {
  width: 100%;
  height: auto;
}


.cls-1 {
  fill: red;
}

.cls-10 {
  font-size: 30px;
  font-family: 'Helvetica' !important;
  fill: white;
}

.cls-11 {
  fill: blue;
}

.cls-12 {
  fill: green;
}

.cls-13 {
  font-weight: bold;
}

.cls-29 {
  font-size: 34px;
  fill: white;
  font-family: 'Helvetica' !important;
  font-weight: bold;
  text-anchor: middle;
}