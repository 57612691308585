.section1outline {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 100px;
    position: relative;
}


.bodytextStyle {
    width: 54%;
    text-align: center;
}

.background-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* animation */
@keyframes moveCloudToLeft {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-50px);
    }
}

@keyframes moveCloudToRight {

    0%,
    100% {
        transform: translateX(-100px);
    }

    50% {
        transform: translateX(-50px);
    }
}

.section1outline #cloud1 {
    animation: moveCloudToLeft 5s linear infinite;
}

.section1outline #cloud2-2 {
    animation: moveCloudToRight 5s linear infinite;
}

.section1outline #cloud4 {
    animation: moveCloudToLeft 5s linear infinite;
}

@keyframes moveWindToRight {
    0% {
        transform: translateX(0);
        opacity: 0;
    }

    10% {
        transform: translateX(-10px);
        opacity: 0.1;
    }

    20% {
        transform: translateX(-20px);
        opacity: 0.2;
    }

    50% {
        transform: translateX(-50px);
        opacity: 1;
    }

    80% {
        transform: translateX(-80px);
        opacity: 0.2;
    }

    90% {
        transform: translateX(-90px);
        opacity: 0.1;
    }

    100% {
        transform: translateX(-100px);
        opacity: 0;
    }
}

@keyframes moveWindToLeft {
    0% {
        transform: translateX(0);
        opacity: 0;
    }

    10% {
        transform: translateX(10px);
        opacity: 0.1;
    }

    20% {
        transform: translateX(20px);
        opacity: 0.2;
    }

    50% {
        transform: translateX(50px);
        opacity: 1;
    }

    80% {
        transform: translateX(80px);
        opacity: 0.2;
    }

    90% {
        transform: translateX(90px);
        opacity: 0.1;
    }

    100% {
        transform: translateX(100px);
        opacity: 0;
    }
}

.section1outline #wind3 {
    animation: moveWindToRight 5s linear infinite;
}

.section1outline #wind1 {
    animation: moveWindToLeft 5s linear infinite;
}

.section1outline #wind2 {
    animation: moveWindToLeft 5s linear infinite;
}


@keyframes rotation {
    0% {
        transform:  rotate(0deg);
      }
    
      100% {
        transform:  rotate(-359deg);
      }
}

#wings {
    transform-origin: center center;
    animation: rotation 8s linear infinite;
    transform-box: border-box;
    transform-box: fill-box;
}

#settingIcon {
    transform-origin: center center;
    animation: rotation 8s linear infinite;
    transform-box: border-box;
    transform-box: fill-box;
}