.section5outline {
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-position: center;
}

@keyframes moveWind1 {

    0%,
    100% {
        transform: translateX(-140px);
    }

    50% {
        transform: translateX(10px);
    }
}

#s5_wind1 {
    animation: moveWind1 6s linear infinite;
}

@keyframes moveWind2 {

    0%,
    100% {
        transform: translateX(180px);
    }

    50% {
        transform: translateX(0px);
    }
}

#s5_wind2 {
    animation: moveWind2 6s linear infinite;
}

#s5_sun {
    animation: zoomAnimation 5s ease-in-out infinite;
    transform-origin: center center;
    transform-box: border-box;
    transform-box: fill-box;
}

#s5_star {
    animation: showHideAnimation 1.8s steps(1) infinite;
}

@keyframes swingdail {

    0%,
    100% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(40deg);
    }
}

#s5_dail {
    transform-origin: bottom;
    animation: swingdail 4s linear infinite;
    transform-box: border-box;
    transform-box: fill-box;
}

#s5_sound1, #s5_sound2, #s5_sound3 {
    opacity: 0; /* Start hidden */
  }
  
  #s5_sound1 {
    animation: retain1 3s steps(1) infinite;
  }
  
  #s5_sound2 {
    animation: retain2 3s steps(1) infinite;
  }
  
  #s5_sound3 {
    animation: retain3 3s steps(1) infinite;
  }
  
  /* Keyframes for each line */
  @keyframes retain1 {
    0% { opacity: 1; } /* Line 1 visible from start */
    90% { opacity: 0; } /* Hidden after all lines hide */
  }
  
  @keyframes retain2 {
    0% { opacity: 0; } /* Hidden initially */
    45% { opacity: 1; } /* Visible when second line comes */
    90% { opacity: 0; } /* Hidden after all lines hide */
  }
  
  @keyframes retain3 {
    0% { opacity: 0; } /* Hidden initially */
    60% { opacity: 1; } /* Visible when third line comes */
    90% { opacity: 0; } /* Hidden after all lines hide */
  }

  @keyframes s5LeftRightXmovementLine {

    0%,
    100% {
        transform: translateX(-100px);
    }

    50% {
        transform: translateX(-700px);
    }
}


#s5_hLine1 {
    animation: s5LeftRightXmovementLine 10s linear infinite;
}

@keyframes s5BottomTopYmovementLine {

    0%,
    100% {
        transform: translateY(-300px);
    }

    50% {
        transform: translateY(100px);
    }
}


#s5_vLine1 {
    animation: s5BottomTopYmovementLine 5s linear infinite;
}

@keyframes s5TopBottomYmovementLine {

    0%,
    100% {
        transform: translateY(590px);
    }

    50% {
        transform: translateY(10px);
    }
}


#s5_vLine2 {
    animation: s5TopBottomYmovementLine 5s linear infinite ;
}