.character-svg-container {
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  background-position: center;
}

.character-responsive-svg {
  width: 100%;
  height: auto;
  margin-top: -110px;
}

#c_previous, #c_next {
  pointer-events: all;
  cursor: pointer;
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  min-width: 80vw;
}